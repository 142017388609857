body{
  overflow: hidden;
}
#studio{
  border-bottom: 3px solid white;
}
#here{
  border-bottom: 3px solid white; 
}
#here:hover{
  cursor: pointer;
}
.section{
  width: 100%;
}
.WorksMenu{
  font-size: 2em;
  font-family: Gruppo;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 12px solid white; */
}

.monkey{
  height: auto;
  width: 100%;
}
#works_summary{
  margin-top: 1em;
  height: 60%;
  overflow-wrap: break-word;
  width: 100%;
}
#section_title{
  font-size: 1.25em;
  font-weight: bold;
}
.works{
  display: flex;
}
#work_title{
  align-self: start;
  height: 5%;
  width: 100%;
  display: flex;
  align-items: center;
}
#underline{
  border-bottom: 5px solid white;
}
.WorkPage{
  font-size: 2em;
  font-family: Gruppo;
  border: 12px solid black;
  display: flex; 
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 75%;
}
#title:hover{
  cursor: pointer;
  border-bottom: 2.5px solid black;
}
.App{
  left: 0;
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
#css, #webgl {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
}
#css {
    transform:scale(1.0);
}

#webgl {
  pointer-events: none;
}
@media only screen and (min-width: 800px) {

  .main{
    font-family: Gruppo;
    font-size: 2em;
  }
  .Untranslated {
    font-family: Libre_Barcode_128;
    font-size: 4em;
  }
  .content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .WorksMenuPage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 10px solid white;
    width: 50%;
  }
  .Contain{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 92%;
    width: 92%;
    background-color: black;
    margin-right: 1em;
  }
  .Right{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  .Left{
    margin-left: 2em;
    display: flex;
    min-width: 50%;
    height: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .main{
    font-family: Gruppo;
    font-size: 1.5em;
  }
  .Right{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 75%;
    height: 100%;
  }
  .Left{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 50%;
    height: 100%;
  }
  #threebarcode{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: -5em;
  }
  #threebarcode:hover{
    width: 100%;
    height: 100%;
  }
}

a{
  -webkit-text-decoration: red;
          text-decoration: red;
}

a:visited{
    color: red;
}

.TranslateButton{
  font-family: Libre_Text;
  font-size: 2em;
  cursor: pointer;
}
.translate{
  color:black;
 -webkit-animation: color_change 0.5s infinite alternate;
 animation: color_change 0.5s infinite alternate;
}


@font-face {
  font-family: Libre_Barcode_128;
  src: url(/static/media/LibreBarcode128-Regular.87c6c4b6.ttf);
}

@font-face {
  font-family: Libre_Text;
  src: url(/static/media/LibreBarcode128Text-Regular.b0e77b86.ttf);
}

@font-face {
  font-family: Gruppo;
  src: url(/static/media/Gruppo-Regular.b9c0b989.ttf);
}

code:hover{
      background-color: white;
      color: black;
}
.hello{
  align-self: flex-start;
  font-family: Gruppo;
  font-size: 1.5em;
  color: black;

  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.start{
  color:black;
 -webkit-animation: color_change 3s ;
 animation: color_change 3s;
}

.constant{
  color:black;
 -webkit-animation: color_change 3s infinite alternate;
 animation: color_change 3s infinite alternate;
}

.ref{
  font-size: 0.75em;
  margin: 0;
  font-family: Gruppo;
  color: white;
}

@-webkit-keyframes color_change {
 from { background-color: black; }
 to { background-color: white;color:white; }
}
@keyframes color_change {
  from { background-color: black; }
  to { background-color: white;color:white; }
}

 @media screen and (orientation:landscape) { 
   .App{
     overflow: hidden;
   }
  }
